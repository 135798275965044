import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call } from 'typed-redux-saga';
import ApiService from 'common/api/ApiService';
import { dashboardActions } from './dashboardPageSlice';
import { InvestmentDetailRemote } from '../investment/shared/investmentDetailRemote';
import { mapInvestmentDetailFromApi } from '../investment/detail/investmentDetailPageMapper';
import { fundSummary } from '../funds/FundTypes';

function* onInit(): Generator {
  yield put(dashboardActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(dashboardActions.setPageLoading);
  try {
    // TODO Load some data.
    const resp = yield* call(ApiService.get<fundSummary[]>(), '/v1/funds-summaries');
    console.log(resp);
    // dashboardActions.setFundSummary(resp)

    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(dashboardActions.init.type, onInit);
}
