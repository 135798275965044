import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { IdLabelLookup } from 'common/util/lookup';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { ReferralDjMode } from '../shared/referralDjMode';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import {
  CreateInvestmentForm, CreateInvestmentPageQueryString, CreateInvestmentPageState, FundType,
} from './createInvestmentPageTypes';
import { CreatedFundQueryString, CreateFundForm } from '../../funds/FundTypes';

export const initialInvestmentForm: CreateInvestmentForm = {
  customerId: null,
  fundId: null,
  formNo: '',
  transactionDateTime: null,
  fundType: FundType.A,
  maturityDate: null,
  amount: null,
  bonusPayoutRate: 0,
  exchangeNetwork: ExchangeNetwork.ERC20,
  walletAddress: '',
  transactionHash: '',
  referralName: '',
  referralPayoutMode: ReferralPayoutMode.Yearly,
  referralDjMode: ReferralDjMode.None,
  referralFee: null,
  referralDjFee: null,
  referralTransactionDateTime: null,
  referralExchangeNetwork: ExchangeNetwork.ERC20,
  referralWalletAddress: '',
  referralTransactionHash: '',
  customerTake: 50,
  companyTake: 50,
};

const initialState: CreateInvestmentPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  formCache: null,
  form: initialInvestmentForm,
  customers: [],
  funds: [],
};

const createInvestmentSlice = createSlice({
  name: 'createInvestment',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<CreatedFundQueryString>) => state,
    createNewCustomer: (state, _action: PayloadAction<CreateInvestmentForm>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setCustomers(state, { payload }: PayloadAction<IdLabelLookup[]>) {
      state.customers = payload;
      return state;
    },
    setFunds(state, { payload }: PayloadAction<IdLabelLookup[]>) {
      state.funds = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<CreateInvestmentForm>) {
      state.form = payload;
      return state;
    },
    setFormCache(state, { payload }: PayloadAction<CreateInvestmentForm|null>) {
      state.formCache = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<CreateInvestmentForm>) => state,
    createNewFund: (state, _action: PayloadAction<CreateInvestmentForm>) => state,

  },
});

export const createInvestmentActions = createInvestmentSlice.actions;
export const createInvestmentReducer = createInvestmentSlice.reducer;
