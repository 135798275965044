import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { DashboardPageState } from './dashboardPageTypes';
import { fundSummary } from '../funds/FundTypes';

const initialState: DashboardPageState = {
  pageLoading: initialLoadingTrueState,
  fundSummaries: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFundSummary(state, { payload } : PayloadAction<fundSummary[]>) {
      state.fundSummaries = payload;
      return state;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
