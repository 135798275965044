import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import emptyFunction from 'common/util/emptyFunction';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { DateTime } from 'luxon';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { InvestmentStatus } from '../shared/investmentStatus';
import { ReferralDjMode } from '../shared/referralDjMode';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import {
  CalculatePayoutInfoNeeded,
  InvestmentDetail, InvestmentDetailPageState,
  InvestmentDetailSpotBalance, InvestmentPartialPayoutForm, InvestmentPartialPayoutPrompt,
  InvestmentPendingSettlementPrompt,
} from './investmentDetailPageTypes';
import { FundType } from '../createInvestment/createInvestmentPageTypes';

const initialPendingSettlementPrompt: InvestmentPendingSettlementPrompt = {
  id: 0,
  name: '',
};

const initialPartialPaymentPrompt: InvestmentPartialPayoutPrompt = {
  id: 0,
  name: '',
  amount: 0,
  originalAmount: 0,
};

const initialState: InvestmentDetailPageState = {
  pageLoading: initialLoadingTrueState,
  detail: {
    id: 0,
    customerId: 0,
    formNo: '',
    transactionDateTime: '',
    amount: 0,
    fundName: '',
    status: InvestmentStatus.Active,
    bonusPayoutRate: 0,
    referralName: '',
    referralFee: 0,
    companyTake: 0,
    customerTake: 0,
    originalAmount: 0,
    currentAmount: 0,
    referralTransactionDateTime: '',
    loginPassword: '',
    totalPartialPayout: 0,
    transactions: [],
    childInvestments: [],
  },
  spotBalance: {
    unrealizedProfitLoss: 0,
    totalMarketValue: 0,
    data: [],
  },
  pendingSettlementLoading: initialLoadingState,
  pendingSettlementPrompt: initialPendingSettlementPrompt,
  partialPaymentPrompt: initialPartialPaymentPrompt,
  partialPaymentLoading: initialLoadingState,
  partialPayoutAmount: 0,
};

const investmentDetailSlice = createSlice({
  name: 'investmentDetail',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _state: PayloadAction<number>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<InvestmentDetail>) {
      state.detail = payload;
      return state;
    },
    setSpotBalance(state, { payload }: PayloadAction<InvestmentDetailSpotBalance>) {
      state.spotBalance = payload;
      return state;
    },
    setPendingSettlementLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pendingSettlementLoading = payload;
      return state;
    },
    setPendingSettlementPrompt(state, { payload }:
      PayloadAction<InvestmentPendingSettlementPrompt|undefined>) {
      state.pendingSettlementPrompt = payload || initialPendingSettlementPrompt;
      return state;
    },
    confirmPendingSettlement: emptyFunction,
    setPartialPaymentPrompt(state, { payload }:
      PayloadAction<InvestmentPartialPayoutPrompt|undefined>) {
      state.partialPaymentPrompt = payload || initialPartialPaymentPrompt;
      // state.partialPayoutAmount = (payload?.amount ?? 0) - (payload?.originalAmount ?? 0);
      return state;
    },
    submitPartialPayment: (state, _payload: PayloadAction<InvestmentPartialPayoutForm>) => state,
    setPartialPaymentLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.partialPaymentLoading = payload;
      return state;
    },
    setAvailablePayout(state, { payload } : PayloadAction<number>) {
      state.partialPayoutAmount = payload;
      return state;
    },
    setAvailablePayoutByDate(state, { payload } : PayloadAction<CalculatePayoutInfoNeeded>) {
      // Need to get State and get state:
      if (payload.selectedDate === null) {
        state.partialPayoutAmount = payload.transactions
          .filter((item) => item.type === 'settlements' || item.type === 'partial_payout')
          .reduce(
            (totalProfit, currentProfit) => currentProfit.amount + totalProfit,
            0,
          );
      } else {
        state.partialPayoutAmount = payload.transactions
          .filter((i) => i.dateTime.diff(payload.selectedDate ?? new DateTime()).milliseconds < 0
              && (i.type === 'settlements' || i.type === 'partial_payout'))
          .reduce(
            (totalProfit, currentProfit) => currentProfit.amount + totalProfit,
            0,
          );
      }

      return state;
    },
  },
});

export const investmentDetailActions = investmentDetailSlice.actions;
export const investmentDetailReducer = investmentDetailSlice.reducer;
