import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { DateTime } from 'luxon';
import { InvestmentStatus } from '../shared/investmentStatus';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import {
  EguyCloseSummaryPageState,
  EguyCloseSummary,
  CloseInvestmentForm,
  EguyCloseSummaryForm,
} from './closeInvestmentPageTypes';
import { InvestmentDetailFundTransactionRemote } from '../shared/investmentDetailRemote';

const initialState: EguyCloseSummaryPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  detail: {
    id: 0,
    formNo: '',
    dateTime: null,
    customerName: '',
    amount: 0,
    finalAmount: 0,
    customerTake: 0,
    companyTake: 0,
    totalPayout: 0,
    totalProfit: 0,
    companyProfit: 0,
    customerProfit: 0,
    profitTransactions: [],
    payoutTransactions: [],
    portfolioTransactions: [],
  },
};

const eguyCloseInvestmentSlice = createSlice({
  name: 'eguySummary',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _state: PayloadAction<number>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<EguyCloseSummary>) {
      state.detail = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<EguyCloseSummaryForm>) => state,
  },
});

export const eguyCloseSummaryActions = eguyCloseInvestmentSlice.actions;
export const eguyCloseSummaryReducer = eguyCloseInvestmentSlice.reducer;
