import * as React from 'react';
import PageTitle from 'common/ui/PageTitle';
import ContentContainer from 'common/ui/ContentContainer';
import Card from 'common/ui/Card';
import { LoadingAlert } from 'common/ui/Alert';
import { formatDateTime } from 'common/util/date';
import { formatCurrency } from 'common/util/currency';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSwitch from 'common/ui/LoadingSwitch';
import { SettlementPageReduxState } from './settlementPageTypes';
import { settlementsActions } from './settlementsPageSlice';

const QuantSettlementListPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SettlementPageReduxState) => s.settlementDetails.pageLoading,
  );
  const detail = useSelector(
    (s: SettlementPageReduxState) => s.settlementDetails.detail,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(settlementsActions.init());
  }, []);

  return (
    <ContentContainer>
      <PageTitle>Settlements</PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card title="Summary">
          <div className="row">
            <div className="col-4">
              <Card title="Current Pool">
                {formatCurrency(detail.currentFundPool, 'two')}
                {' '}
                USDT
              </Card>
            </div>
            <div className="col-4">
              <Card title="PnL Up to Today">
                {detail.pnl > 0 && (
                  <>
                    <span className="text-success">
                      +
                      {' '}
                      {formatCurrency(detail.pnl, 'two')}
                      {' '}
                      %
                    </span>
                  </>
                )}
                {detail.pnl < 0 && (
                  <>
                    <span className="text-warning">
                      -
                      {' '}
                      {formatCurrency(detail.pnl, 'two')}
                      {' '}
                      %
                    </span>
                  </>
                )}
              </Card>
            </div>
            <div className="col-4">
              <Card title="Invovled Investments">
                {detail.activeInvestors}
              </Card>
            </div>
          </div>
        </Card>
        <div className="my-4" />
        <Card title="Settlements List">
          {/* <LoadingAlert loading={formLoading} /> */}
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Transaction Date</th>
                <th scope="col">Fund</th>
                <th scope="col">Before Settlement Pool</th>
                <th scope="col">Amount</th>
                <th scope="col">After Settlement Pool</th>
                <th scope="col">Affected Investors</th>
              </tr>
            </thead>
            <tbody>
              {detail.settlements.map((i) => (
                <tr key={i.id}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{i.fundName}</td>
                  <td>{formatCurrency(i.currentAmount, 'two')}</td>
                  <td>
                    {
                        i.amount > 0 && (
                        <>
                          <span className="text-success">
                            {`${formatCurrency(i.amount, 'two')}`}
                            <small>
                              {' '}
                              (
                              {`${formatCurrency(i.percentage, 'two')}`}
                              {' '}
                              %)
                            </small>
                          </span>
                        </>
                        )
                      }
                    {
                          i.amount < 0 && (
                          <>
                            <span className="text-danger">
                              {`${formatCurrency(i.amount, 'two')}`}
                              <small>
                                {' '}
                                (
                                {`${formatCurrency(i.percentage, 'two')}`}
                                {' '}
                                %)
                              </small>
                            </span>
                          </>
                          )
                      }

                  </td>
                  <td>{formatCurrency(i.afterAmount, 'two')}</td>
                  <td>{i.totalPax}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default QuantSettlementListPage;
