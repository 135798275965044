import { toApiDate, toApiDateTime } from 'common/util/date';
import getLocalId from 'common/util/getLocalId';
import { CreateInvestmentForm } from './createInvestmentPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapCreateInvestmentToApi = (form: CreateInvestmentForm)
  : unknown => ({
  id: getLocalId(),
  fund_id: form.fundId,
  customer_id: form.customerId,
  form_no: form.formNo,
  transaction_date_time: toApiDateTime(form.transactionDateTime),
  maturity_date: toApiDate(form.maturityDate),
  amount: form.amount,
  fund_type: form.fundType,
  exchange_network: form.exchangeNetwork,
  wallet_address: form.walletAddress,
  transaction_hash: form.transactionHash,
  bonus_payout_rate: form.bonusPayoutRate,
  customer_take: form.customerTake,
  company_take: form.companyTake,
  referral_name: form.referralName,
  referral_payout_mode: form.referralPayoutMode,
  referral_dj_mode: form.referralDjMode,
  referral_fee: form.referralFee,
  referral_dj_fee: form.referralDjFee || 0,
  referral_transaction_date_time: toApiDateTime(form.referralTransactionDateTime),
  referral_exchange_network: form.referralExchangeNetwork,
  referral_wallet_address: form.referralWalletAddress,
  referral_transaction_hash: form.referralTransactionHash,
});
