import Big from 'big.js';
import { formatDate, formatDateTime, fromApiDate } from 'common/util/date';
import { TradePrice } from 'context/trade/tradeSlice';
import { after } from 'lodash';
import { InvestmentDetailRemote } from '../shared/investmentDetailRemote';
import { InvestmentTransactionType } from '../shared/investmentTransactionType';
import {
  InvestmentDetail, InvestmentDetailFuture, InvestmentDetailFutureSettlement,
  InvestmentDetailSpotBalance, InvestmentDetailSpotBalanceEntry,
  InvestmentDetailSpotTrade, InvestmentDetailTransaction,
} from './investmentDetailPageTypes';
import { FundType } from '../createInvestment/createInvestmentPageTypes';

const mapSpotTrades = (response: InvestmentDetailRemote): InvestmentDetailSpotTrade[] => {
  const { spot_buys: buys, spot_sells: sells } = response;

  const mappedBuys = buys.map<InvestmentDetailSpotTrade>((b) => ({
    type: 'buy',
    id: b.id,
    dateTime: fromApiDate(b.date_time),
    quantity: b.allocate_quantity,
    unitPrice: b.unit_price,
    total: b.allocate_total,
    tradeName: b.trade_name,
    coinGeckoId: b.coingecko_id,
    tradeColor: b.trade_color,
  }));

  const mappedSells = sells.map<InvestmentDetailSpotTrade>((b) => ({
    type: 'sell',
    id: b.id,
    dateTime: fromApiDate(b.date_time),
    quantity: b.allocate_quantity,
    unitPrice: b.unit_price,
    total: b.allocate_total,
    tradeName: b.trade_name,
    coinGeckoId: b.coingecko_id,
    tradeColor: b.trade_color,
  }));

  return [...mappedBuys, ...mappedSells]
    .sort((a, b) => a.dateTime.diff(b.dateTime).valueOf());
};

const mapFuture = (response: InvestmentDetailRemote): InvestmentDetailFuture => {
  const { future_fund_transactions: futureTrx, spot_fund_transactions: spotTrx } = response;
  const depositTrx = response.future_fund_transactions.find((t) => t.type === 'deposit');

  const settlements = response.future_settlements.reduce<InvestmentDetailFutureSettlement[]>(
    (prev, cur) => {
      // Find mapping transaction.
      const trx = futureTrx.find((t) => t.related_to_id === cur.id && t.type === 'future_settlement')
      || spotTrx.find((t) => t.related_to_id === cur.id && t.type === 'future_settlement');

      let accountAmount = depositTrx?.amount || 0;
      if (prev.length > 0) {
        const lastRecord = prev[prev.length - 1];
        accountAmount = lastRecord.settlementAmount < 0
          ? lastRecord.accountAmount + lastRecord.settlementAmount
          : lastRecord.accountAmount;
      }
      const settlementAmount = trx?.amount || 0;

      prev.push({
        id: cur.id,
        accountAmount,
        dateTime: fromApiDate(cur.date_time),
        percent: cur.settlement_percent,
        settlementAmount,
      });

      return prev;
    }, [],
  );

  return {
    initialAmount: depositTrx?.amount || 0,
    balanceAmount: response.future_fund_balance,
    settlements,
  };
};

export const mapInvestmentDetailFromApi = (response: InvestmentDetailRemote)
  : InvestmentDetail => ({
  id: response.id,
  customerId: response.customer_id,
  formNo: response.form_no,
  status: response.status,
  transactionDateTime: formatDateTime(fromApiDate(response.transaction_date_time)),
  amount: response.amount,
  fundName: response.fund_name,
  bonusPayoutRate: response.bonus_payout_rate,
  referralName: response.referral_name,
  referralFee: response.referral_fee,
  referralTransactionDateTime: formatDateTime(fromApiDate(response.referral_transaction_date_time)),
  loginPassword: response.login_password,
  companyTake: response.company_take,
  customerTake: response.customer_take,
  originalAmount: response.original_amount,
  currentAmount: response.current_amount,
  transactions: response.transactions
    .sort((a, b) => {
      const aDate = fromApiDate(a.date_time);
      const bDate = fromApiDate(b.date_time);
      return aDate.diff(bDate).valueOf();
    })
    .reduce<InvestmentDetailTransaction[]>(
      (prev, current, currentIndex) => {
        let beforeAmount = 0;
        let afterAmount = 0;
        let percentage : number|string = '';
        if (currentIndex === 0) {
          afterAmount += current.amount;
          percentage = 'N/A';
        } else {
          beforeAmount = prev[prev.length - 1].afterAmount;
          afterAmount = beforeAmount + current.amount;
          percentage = current.amount / beforeAmount;
          percentage *= 100;
        }
        prev.push({
          id: current.id,
          beforeAmount,
          dateTime: fromApiDate(current.date_time),
          amount: current.amount,
          type: current.type,
          afterAmount,
          percentage,
        });
        return prev;
      }, [],
    ),

  childInvestments: response.child_investments.map((i) => ({
    id: i.id,
    formNo: i.form_no,
    amount: i.amount,
  })),
  totalPartialPayout: response.transactions
    .filter((tx) => tx.type === InvestmentTransactionType.PartialPayout)
    .reduce((acc, tx) => acc + Math.abs(tx.amount), 0),
  settlement: response.investment_settlement ? {
    id: response.investment_settlement.id,
    date: formatDate(fromApiDate(response.investment_settlement.date)),
    amount: response.investment_settlement.amount,
    djProfit: response.investment_settlement.dj_profit,
    companyProfit: response.investment_settlement.company_profit,
    investorProfit: response.investment_settlement.investor_profit,
    referralProfit: response.investment_settlement.referral_profit,
  } : undefined,
});

export const mapInvestmentDetailSpotBalanceFromApi = (
  resp: InvestmentDetailRemote,
  prices: Record<string, TradePrice>,
): InvestmentDetailSpotBalance => {
  const result:InvestmentDetailSpotBalanceEntry[] = [];

  // Calculate USDT
  const usdt = resp.spot_fund_transactions.reduce((acc, t) => acc + t.amount, 0);
  result.push({
    balance: usdt,
    name: 'USDT',
    price: 1,
    total: usdt,
    color: '#000', // Currently hardcoding USDT color.
  });

  Object.entries(prices).forEach(([id, tradePrice]) => {
    // Loop through all transactions that has the id assigned.
    const buyRecords = resp.spot_buys
      .filter((b) => b.coingecko_id === id);
    const buyQty = buyRecords
      .reduce((acc, b) => acc + b.allocate_quantity, 0);
    const sellQty = resp.spot_sells
      .filter((b) => b.coingecko_id === id)
      .reduce((acc, b) => acc + b.allocate_quantity, 0);

    const remain = buyQty - sellQty;
    if (remain > 0) {
      result.push({
        name: buyRecords[0]?.trade_name,
        balance: remain,
        price: tradePrice.price,
        color: buyRecords[0]?.trade_color || '#000',
        total: new Big(tradePrice.price)
          .mul(remain)
          .round(2, 0)
          .toNumber(),
      });
    }
  });
  const spotBaseAmount = resp.spot_fund_transactions.find((s) => s.type === 'deposit')
    ?.amount || 0;

  const totalMarketValue = result.reduce((acc, r) => acc + r.total, 0);
  const unrealizedProfitLoss = ((totalMarketValue / spotBaseAmount) * 100) - 100;

  return {
    totalMarketValue,
    unrealizedProfitLoss,
    data: result,
  };
};
