import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import ApiService from 'common/api';
import { toApiDateTime } from 'common/util/date';
import toastActions from 'common/ui/Toast/toastActions';
import history from 'common/setup/history';
import { Action } from 'redux';
import { call } from 'typed-redux-saga';
import { createFundActions } from './createFundSlice';
import { FundTypeInfo } from '../FundTypes';

function* onInit(): Generator {
  yield put(createFundActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    createFundActions.setPageLoading,
  );
  try {
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!createFundActions.submit.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    createFundActions.setFormLoading,
  );
  yield put(loadingStart());

  const form = action.payload;

  try {
    const result = yield* call(ApiService.post<FundTypeInfo>(), '/v1/funds', {
      name: form.name,
    });

    toastActions.success('Fund Added done');
    yield put(loadingSuccess('', result.id));
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(createFundActions.init.type, onInit);
  yield takeLatest(createFundActions.submit.type, onSubmit);
}
