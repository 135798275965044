import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import ApiService from 'common/api';
import { toApiDateTime } from 'common/util/date';
import toastActions from 'common/ui/Toast/toastActions';
import history from 'common/setup/history';
import { Action } from 'redux';
import { call } from 'typed-redux-saga';
import { IdLabelLookup } from 'common/util/lookup';
import { newQuantSettlementActions } from './newQuantSettlementSlice';
import { createInvestmentActions } from '../../investment/createInvestment/createInvestmentPageSlice';

function* onInit(): Generator {
  yield put(newQuantSettlementActions.setInitialState());

  const [, loadingFail, loadingSuccess] = bindLoadingActions(
    newQuantSettlementActions.setPageLoading,
  );

  try {
    // const customers = yield* call(ApiService.get<IdLabelLookup[]>(), '/v1/customers/lookup');
    // yield put(createInvestmentActions.setCustomers(customers));
    const funds = yield* call(ApiService.get<IdLabelLookup[]>(), '/v1/funds');
    console.log(funds);
    yield put(newQuantSettlementActions.setFunds(funds));

    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }

  try {
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* onSubmit(action: Action): Generator {
  if (!newQuantSettlementActions.submit.match(action)) { return; }

  const [loadingStart, loadingFail, loadingSuccess] = bindLoadingActions(
    newQuantSettlementActions.setFormLoading,
  );
  yield put(loadingStart());

  const form = action.payload;

  try {
    yield* call(ApiService.post(), '/v1/quant-settlements', {
      settlement_date: toApiDateTime(form.dateTime),
      amount: form.amount,
      fund_id: form.fundId,
    });

    toastActions.success('Settlement done');
    history.push('/member/settlements/list');
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(newQuantSettlementActions.init.type, onInit);
  yield takeLatest(newQuantSettlementActions.submit.type, onSubmit);
}
