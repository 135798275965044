import React from 'react';
import { formatCurrency } from 'common/util/currency';
import Card from 'common/ui/Card';
import { toApiDate } from 'common/util/date';
import { QuestionIcon } from '@primer/octicons-react';
import { Tooltip } from 'react-tooltip';
import { InvestmentDetail, InvestmentDetailSettlement, InvestmentDetailTransaction } from '../investmentDetailPageTypes';
import './index.css';
import { InvestmentTransactionType } from '../../shared/investmentTransactionType';

type Props = {
  detail: {
    companyTake : number,
    customerTake : number,
    transactions: InvestmentDetailTransaction[],
    totalPartialPayout : number,
    amount: number,
    currentAmount: number,
  }
}

interface profitBreakdown {
  date: string | undefined,
  currentProfit: number,
  accumulatedProfit: number,
  companyTake: number,
  customerTake: number,
  accumulatedCompanyTake: number,
  accumulatedCustomerTake: number,
}

const SettlementInfo: React.FC<Props> = ({ detail }) => {
  const companyRate = detail.companyTake / 100;
  const customerRate = detail.customerTake / 100;

  const totalProfit = detail.transactions
    .filter((item) => item.type === InvestmentTransactionType.Settlements)
    .reduce((prev, current) => prev + current.amount, 0);

  const companyProfit = totalProfit * companyRate;
  const customerProfit = totalProfit * customerRate;

  const profitBreakdown = detail.transactions
    .filter((item) => item.type === InvestmentTransactionType.Settlements)
    .reduce<profitBreakdown[]>((prev, current, index) => {
      const currentProfit = current.amount;
      let accumulated_profit = current.amount;
      if (prev.length > 0) {
        accumulated_profit = prev[prev.length - 1].accumulatedProfit + current.amount;
      }
      prev.push({
        date: toApiDate(current.dateTime),
        currentProfit,
        accumulatedProfit: accumulated_profit,
        companyTake: currentProfit * companyRate,
        customerTake: currentProfit * customerRate,
        accumulatedCompanyTake: accumulated_profit * companyRate,
        accumulatedCustomerTake: accumulated_profit * customerRate,
      });
      return prev;
    }, []);

  return (
    <>
      <Card title="Investment Summaries">
        <div className="row ">
          <div className="col-4">
            <p>Invested Amount</p>
            <h5>
              {formatCurrency(detail.amount, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>

          <div className="col-4">
            <p>
              Total Early Withdrawal
              <span
                className="pl-2"
                data-tooltip-id="formula-tooltip"
                data-tooltip-content="Sum of all early payout"
                data-tooltip-place="top"
              >
                <QuestionIcon />
              </span>
            </p>
            <h5>
              {formatCurrency(detail.totalPartialPayout, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
          <div className="col-4">
            <p>
              Closing Amount
              <span
                className="pl-2"
                data-tooltip-id="formula-tooltip"
                data-tooltip-content="Invest Amount + Total Profit - Totaly Early Withdrawal"
                data-tooltip-place="top"
              >
                <QuestionIcon />
              </span>
            </p>
            <h5>
              {formatCurrency(detail.currentAmount, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
        </div>
        <hr className="my-2" />
        <div className="row">
          <div className="col-12">
            <h5>Profits</h5>
          </div>
          <div className="col-4">
            <span className="badge badge-success py-2 px-4 font-weight-normal">
              Total Profit
            </span>
            <h5>
              {formatCurrency(totalProfit, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
          <div className="col-4">
            <span className="badge badge-primary py-2 px-4 font-weight-normal">
              Investor (
              {detail.customerTake}
              %)
            </span>
            <h5 className="">
              {formatCurrency(customerProfit, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
          <div className="col-4">
            <span className="badge badge-info py-2 px-4 font-weight-normal">
              Company (
              {detail.companyTake}
              %)
            </span>
            <h5 className="">
              {formatCurrency(companyProfit, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
        </div>
      </Card>
      <div className="mb-4" />
      <Card title="Profit Breakdown">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center" rowSpan={2}>Date</th>
              <th className="text-center" rowSpan={2}>
                Amount
                <br />
                (USDT)
              </th>
              <th className="text-center" rowSpan={2}>
                Investor Share
                <br />
                (
                {detail.customerTake}
                %)
              </th>
              <th className="text-center" rowSpan={2}>
                Company Share
                <br />
                (
                {detail.companyTake}
                %)
              </th>
              <th className="text-center" colSpan={3}>Accumulated Profit (USDT)</th>
            </tr>
            <tr>
              <th>Total</th>
              <th>Investor Share</th>
              <th>Company Share</th>
            </tr>
          </thead>
          <tbody>
            {
          profitBreakdown.map((value, index) => (
            <tr>
              <td>{value.date}</td>
              <td>{formatCurrency(value.currentProfit, 'two')}</td>
              <td>{formatCurrency(value.customerTake, 'two')}</td>
              <td>{formatCurrency(value.companyTake, 'two')}</td>
              <td className={index === profitBreakdown.length - 1 ? 'font-weight-bold' : ''}>{formatCurrency(value.accumulatedProfit, 'two')}</td>
              <td className={index === profitBreakdown.length - 1 ? 'font-weight-bold' : ''}>{formatCurrency(value.accumulatedCustomerTake, 'two')}</td>
              <td className={index === profitBreakdown.length - 1 ? 'font-weight-bold' : ''}>{formatCurrency(value.accumulatedCompanyTake, 'two')}</td>
            </tr>
          ))

        }
          </tbody>
        </table>
      </Card>
      <div className="mb-4" />

      <Tooltip id="formula-tooltip" />
    </>
  );
};

export default SettlementInfo;
