import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { IdLabelLookup } from 'common/util/lookup';
import { NewQuantSettlementForm, NewQuantSettlementFormState } from './newQuantSettlementType';
import { FundType } from '../../investment/createInvestment/createInvestmentPageTypes';

const initialState: NewQuantSettlementFormState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  form: {
    dateTime: null,
    amount: null,
    fundId: null,
  },
  funds: [],
};

const newQuantSettlementSlice = createSlice({
  name: 'newQuantSettlementSlice',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<NewQuantSettlementForm>) => state,
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<NewQuantSettlementForm>) {
      state.form = payload;
      return state;
    },
    setFunds(state, { payload }: PayloadAction<IdLabelLookup[]>) {
      state.funds = payload;
      return state;
    },
  },
});

export const newQuantSettlementActions = newQuantSettlementSlice.actions;
export const newQuantSettlementReducer = newQuantSettlementSlice.reducer;
