import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import emptyFunction from 'common/util/emptyFunction';
import { FundType } from '../../investment/createInvestment/createInvestmentPageTypes';
import { CreateFundForm, CreateFundPageState } from '../FundTypes';

const initialState: CreateFundPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  form: {
    name: '',
  },
};

const createFundSlice = createSlice({
  name: 'createFundSlice',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: emptyFunction,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<CreateFundForm>) {
      state.form = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<CreateFundForm>) => state,
  },
});

export const createFundActions = createFundSlice.actions;
export const createFundReducer = createFundSlice.reducer;
