import {
  put, takeLatest,
} from 'redux-saga/effects';
import { bindLoadingActions } from 'common/util/loading';
import { call } from 'typed-redux-saga';
import { downloadBinary } from 'common/util/downloadFile';
import ApiService from 'common/api';
import { closeReportActions } from './closeReportPageSlice';
import { mapInvestmentCloseListItemFromApi } from './closeReportPageMapper';
import { InvestmentCloseListItem, InvestmentCloseListItemRemote } from './closeReportPageTypes';

interface excelData{
  summaries: InvestmentCloseListItem[],
  type: string,
}
const exportAsExcel = async (
  summaries: InvestmentCloseListItem[],
  type : string,
): Promise<void> => {
  const { default: Excel } = await import('exceljs');

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Investment List');

  worksheet.columns = [{
    header: 'Form No.',
    key: nameof(summaries[0].formNo),
    width: 10,
  }, {
    header: 'Fund',
    key: nameof(summaries[0].customerName),
    width: 30,
  }, {
    header: 'Status',
    key: nameof(summaries[0].status),
    width: type === 'all' ? 20 : 0,
  },
  {
    header: 'Company Take (%)',
    key: nameof(summaries[0].companyTake),
    width: 20,
  }, {
    header: 'Customer Take (%)',
    key: nameof(summaries[0].customerTake),
    width: 20,
  },
  {
    header: 'Start Date',
    key: nameof(summaries[0].transactionDateTime),
    style: { numFmt: 'dd/mm/yyyy' },
    width: 20,
  }, {
    header: 'Close Date',
    key: nameof(summaries[0].closeDate),
    style: { numFmt: 'dd/mm/yyyy' },
    width: type !== 'closed' ? 0 : 20,
  }, {
    header: type !== 'closed' ? 'Invested Amount' : 'Initial Amount',
    key: nameof(summaries[0].amount),
    width: 20,
  }, {
    header: type === 'active' ? 'Profit Up to Date' : 'Total Profits',
    key: nameof(summaries[0].totalProfit),
    width: 20,
  }, {
    header: 'Total Partial Payout',
    key: nameof(summaries[0].totalPartialPayout),
    width: 20,
  },
  {
    header: type === 'active' ? 'Current Amount' : 'Closing Amount',
    key: nameof(summaries[0].closeAmount),
    width: 20,
  },
  {
    header: type === 'active' ? 'Company Profit (Up to Date)' : 'Company Profit',
    key: nameof(summaries[0].companyProfit),
    width: 30,
  },
  {
    header: type === 'active' ? 'Investor Profit (Up to Date)' : 'Investor Profit',
    key: nameof(summaries[0].investorProfit),
    width: 30,
  },
  ];

  worksheet.addRows(summaries);

  // Highlight unclean records
  summaries.forEach((s, i) => {
    if (!s.isClean) {
      worksheet.getRow(i + 2).fill = {
        type: 'pattern',
        pattern: 'darkVertical',
        fgColor: {
          argb: '00FFCCCC',
        },
      };
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const binary = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  let fileName = '';
  switch (type) {
    case 'active':
      fileName = 'UmbrellaFund Active Investment List.xlsx';
      break;
    case 'pending_settlement':
      fileName = 'UmbrellaFund Pending Settlement Investment List.xlsx';
      break;
    case 'closed':
      fileName = 'UmbrellaFund Closed Investment List.xlsx';
      break;
    default:
      fileName = 'UmbrellaFund Full Investment List.xlsx';
      break;
  }
  downloadBinary(binary, fileName);
};

function* onInit(): Generator {
  yield put(closeReportActions.setInitialState());
}

function* onSubmit(): Generator {
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(closeReportActions.setPageLoading);

  yield put(loadingStart());

  try {
    const response = yield* call(ApiService.get<InvestmentCloseListItemRemote[]>(), '/v1/investments/settlement_report');
    const summaries = response.map((r) => mapInvestmentCloseListItemFromApi(r));

    yield* call(exportAsExcel, summaries, 'all');
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* getClosedReport(): Generator {
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(closeReportActions.setPageLoading);

  yield put(loadingStart());

  try {
    const response = yield* call(ApiService.get<InvestmentCloseListItemRemote[]>(), '/v1/investments/settlement_report?type=closed');
    const summaries = response.map((r) => mapInvestmentCloseListItemFromApi(r));

    yield* call(exportAsExcel, summaries, 'closed');
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

function* getPendingSettlementReport(): Generator {
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(closeReportActions.setPageLoading);

  yield put(loadingStart());

  try {
    const response = yield* call(ApiService.get<InvestmentCloseListItemRemote[]>(), '/v1/investments/settlement_report?type=pending_settlement');
    const summaries = response.map((r) => mapInvestmentCloseListItemFromApi(r));

    yield* call(exportAsExcel, summaries, 'pending_settlement');
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}
function* getActiveInvestmentReport(): Generator {
  const [
    loadingStart, loadingFail, loadingSuccess,
  ] = bindLoadingActions(closeReportActions.setPageLoading);

  yield put(loadingStart());

  try {
    const response = yield* call(ApiService.get<InvestmentCloseListItemRemote[]>(), '/v1/investments/settlement_report?type=active');
    const summaries = response.map((r) => mapInvestmentCloseListItemFromApi(r));

    yield* call(exportAsExcel, summaries, 'active');
    yield put(loadingSuccess());
  } catch (e) {
    yield put(loadingFail(e.message));
  }
}

export default function* mainSaga(): Generator {
  yield takeLatest(closeReportActions.init.type, onInit);
  yield takeLatest(closeReportActions.submit.type, onSubmit);
  yield takeLatest(closeReportActions.getClosedReport.type, getClosedReport);
  yield takeLatest(closeReportActions.getPendingSettlementReport.type, getPendingSettlementReport);
  yield takeLatest(closeReportActions.getActiveInvestmentReport.type, getActiveInvestmentReport);
}
