import * as React from 'react';
import {
  Router, Route, Switch,
} from 'react-router-dom';
import NotFoundPage from 'common/ui/NotFoundPage';
import AppContainer from 'common/ui/AppContainer';

import history from './history';
import ProtectedRoute from './routeComponents/ProtectedRoute';
import BasePage from './routeComponents/BasePage';
import SignInPage from '../../auth/signIn';
import SignInCallbackPage from '../../auth/signInCallback';
import DashboardPage from '../../dashboard';
import CreateCustomerPage from '../../customer/createCustomer';
import CreateInvestmentPage from '../../investment/createInvestment';
import InvestmentListPage from '../../investment/list';
import InvestmentDetailPage from '../../investment/detail';
import EditInvestmentPage from '../../investment/editInvestment';
import CreateTradePage from '../../spot/create';
import SpotListPage from '../../spot/list';
import SpotDetailPage from '../../spot/detail';
import FutureDetailPage from '../../future/detail';
import CreateFutureSettlementPage from '../../future/settlement';
import SpotSummaryReportPage from '../../report/spotSummary';
import CustomerPortalPage from '../../customerPortal';
import CloseInvestmentPage from '../../investment/close';
import SpotQuantityByMaturityPage from '../../spot/spotQuantityByMaturity';
import CloseReportPage from '../../report/closeReport';
import QuantSettlementListPage from '../../quant-settlements/list';
import NewQuantSettlementPage from '../../quant-settlements/create';
import CreateFund from '../../funds/create';

const Routes: React.FunctionComponent = () => (
  <Router history={history}>
    <Switch>
      <Route>
        <Switch>
          <Route exact path="/" component={CustomerPortalPage} />
          <Route exact path="/member" component={BasePage} />
          <Route exact path="/auth/sign-in" component={SignInPage} />
          <Route exact path="/auth/sign-in-callback" component={SignInCallbackPage} />
          <Route path="/member" exact component={BasePage} />

          <ProtectedRoute path="/member">
            <AppContainer>
              <Switch>
                <Route path="/member/dashboard" component={DashboardPage} />
                <Route path="/member/investment/create" component={CreateInvestmentPage} />
                <Route path="/member/investment/list" component={InvestmentListPage} />
                <Route path="/member/investment/detail/:id" component={InvestmentDetailPage} />
                <Route path="/member/investment/edit/:id" component={EditInvestmentPage} />
                <Route path="/member/investment/close/:id" component={CloseInvestmentPage} />
                <Route path="/member/customer/create" component={CreateCustomerPage} />
                <Route path="/member/customer/edit/:id" component={CreateCustomerPage} />
                <Route path="/member/spot-trade/create" component={CreateTradePage} />
                <Route path="/member/spot-trade/list" component={SpotListPage} />
                <Route path="/member/spot-trade/detail/:id" component={SpotDetailPage} />
                <Route path="/member/spot-trade/spot-quantity-by-maturity/" component={SpotQuantityByMaturityPage} />
                <Route path="/member/future/detail/" component={FutureDetailPage} />
                <Route path="/member/future/settlement/" component={CreateFutureSettlementPage} />
                <Route path="/member/funds/create" component={CreateFund} />
                <Route path="/member/report/spot-account/" component={SpotSummaryReportPage} />
                <Route path="/member/report/close-report/" component={CloseReportPage} />
                <Route path="/member/settlements/list" component={QuantSettlementListPage} />
                <Route path="/member/settlements/create" component={NewQuantSettlementPage} />

                {/* Not Found Page: DO NOT put anything below this */}
                <Route component={NotFoundPage} />
              </Switch>
            </AppContainer>
          </ProtectedRoute>

          {/* Not Found Page: DO NOT put anything below this */}
          <Route component={NotFoundPage} />
        </Switch>
      </Route>
    </Switch>
  </Router>
);

export default Routes;
