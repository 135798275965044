import { ISagaModule } from 'redux-dynamic-modules-saga';
import { createInvestmentReducer } from '../../investment/createInvestment/createInvestmentPageSlice';
import createInvestmentSaga from '../../investment/createInvestment/createInvestmentPageSaga';
import { CreateFundReduxState } from '../FundTypes';
import { createFundReducer } from './createFundSlice';
import createFundSaga from './createFundSaga';

export default () : ISagaModule<CreateFundReduxState> => ({
  id: 'createFund',
  reducerMap: {
    createFund: createFundReducer,
  },
  sagas: [createFundSaga],
  retained: true,
});
