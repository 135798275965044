import { LoadingData } from 'common/util/loading';
import { IdLabelLookup, ValueLabelLookup } from 'common/util/lookup';
import { DateTime } from 'luxon';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { ReferralDjMode } from '../shared/referralDjMode';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { InvestmentCloseType } from '../close/investmentCloseType';

export enum FundType{
  NA = '',
  A = 'a',
  B = 'b',
  C = 'c',
}

export const fundTypeValues: ValueLabelLookup[] = [
  { label: nameof(FundType.A), value: FundType.A },
  { label: nameof(FundType.B), value: FundType.B },
  { label: nameof(FundType.C), value: FundType.C },
];

export interface CreateInvestmentPageState {
  pageLoading: LoadingData,
  formLoading: LoadingData,
  customers: IdLabelLookup[],
  funds: IdLabelLookup[],
  formCache: CreateInvestmentForm|null,
  form: CreateInvestmentForm,
}

export interface CreateInvestmentPageQueryString {
  createdCustomer?: number,
}

export interface CreateInvestmentForm {
  customerId: number|null,
  formNo: string,
  fundId: number|null,
  fundType: FundType,
  transactionDateTime: DateTime|null,
  maturityDate: DateTime|null,
  amount: number|null,
  bonusPayoutRate: number,
  exchangeNetwork: ExchangeNetwork,
  walletAddress: string,
  transactionHash: string,
  referralName: string,
  referralPayoutMode: ReferralPayoutMode,
  referralDjMode: ReferralDjMode,
  referralFee: number|null,
  referralDjFee: number|null,
  referralTransactionDateTime: DateTime|null,
  referralExchangeNetwork: ExchangeNetwork,
  referralWalletAddress: string,
  referralTransactionHash: string,
  customerTake: number,
  companyTake: number
}

export interface CreateInvestmentPageReduxState {
  createInvestment: CreateInvestmentPageState;
}
