import { Validate, stringValidator, dateValidator } from 'common/form/validations';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { CreateInvestmentForm } from './createInvestmentPageTypes';

const validateCreateInvestmentForm: Validate<CreateInvestmentForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.fundId, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.formNo, 1),
      stringValidator.required(),
    )
    .validateField(
      nameof.full(form.transactionDateTime, 1),
      dateValidator.required(),
    )
    .validateField(
      nameof.full(form.amount, 1),
      stringValidator.required(),
    );

  return validator;
};

export default validateCreateInvestmentForm;
