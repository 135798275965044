import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import LoadingSwitch from 'common/ui/LoadingSwitch';
import {
  FormikDatePicker, FormikDropDownList, FormikForm, FormikNumberField, FormikTextField,
} from 'common/form';
import Card from 'common/ui/Card';
import { LoadingAlert } from 'common/ui/Alert';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button from 'common/ui/Button';
import { fundTypeValues } from '../../investment/createInvestment/createInvestmentPageTypes';
import validateQuantSettlementForm from '../../quant-settlements/create/newQuantSettlementPageValidation';
import { newQuantSettlementActions } from '../../quant-settlements/create/newQuantSettlementSlice';
import { NewQuantSettlementPageReduxState } from '../../quant-settlements/create/newQuantSettlementType';
import { CreateCustomerPageParam } from '../../customer/createCustomer/createCustomerPageTypes';
import { CreateFundReduxState } from '../FundTypes';
import { createFundActions } from './createFundSlice';
import validateCreateFundForm from './validateCreateFundForm';

const CreateFund: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CreateFundReduxState) => s.createFund.pageLoading,
  );
  const formLoading = useSelector(
    (s: CreateFundReduxState) => s.createFund.formLoading,
  );
  const form = useSelector(
    (s: CreateFundReduxState) => s.createFund.form,
  );

  const isFirstRender = React.useRef(false);
  React.useLayoutEffect(() => {
    if (!isFirstRender.current) {
      isFirstRender.current = true;
      return;
    }

    if (formLoading.isSuccess) {
      history.goBack();
    }
  }, [formLoading]);

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(createFundActions.init());
  }, []);

  const onSubmit = React.useCallback((f) => {
    dispatch(createFundActions.submit(f));
  }, [dispatch]);

  return (
    <ContentContainer>
      <PageTitle>New Fund</PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <FormikForm
          initialValues={form}
          onSubmit={onSubmit}
          validate={validateCreateFundForm}
        >
          {({ values, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Card title="Fund Info">
                <LoadingAlert loading={formLoading} />
                <div className="row">
                  <div className="col-12">
                    <FormikTextField
                      id={nameof(values.name)}
                      name={nameof(values.name)}
                      label="Name"
                    />
                  </div>
                </div>
              </Card>
              <FooterActionContainer>
                <Button type="submit" label="Save" isLoading={formLoading.isLoading} />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};
export default CreateFund;
