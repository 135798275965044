import { Validate, stringValidator } from 'common/form/validations';
import { CreateFundForm } from '../FundTypes';

const validateCreateFundForm
    : Validate<CreateFundForm> = ({ validator, form }) => {
      validator
        .validateField(
          nameof.full(form.name, 1),
          stringValidator.required(),
        );

      return validator;
    };

export default validateCreateFundForm;
